<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">{{ appName }}</h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">¡Tu cuenta está desactivada! 😔</h2>
        <p class="mb-2">
          Su cuenta está desactivada debido a uno de los siguientes motivos:
        </p>
        <ul>
          <li>El personal de administrativo ha desactivó su cuenta.</li>
        </ul>
        <p class="mb-2">
          Si cree que fue una equivocacion comuniquese con el administrador.
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          @click.prevent="signOut"
          >Cerrar session</b-button
        >
        <b-img fluid :src="imgUrl" alt="Not authorized page" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapGetters, mapActions } from "vuex";
import { BLink, BImg, BButton } from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";

export default {
  components: {
    BLink,
    BImg,
    BButton,
    VuexyLogo,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/not-authorized.svg"),
      appName: process.env.VUE_APP_NOMBRE_EMPRESA,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/not-authorized-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      signOutActions: "auth/signOut",
    }),
    signOut() {
      this.signOutActions()
        .then(() => {
          this.$router.push({ name: "login" });
        })
        .catch(() => {
          console.log("error");
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
